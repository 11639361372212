import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useLogout from '../hooks/useLogout';
import { endpoints } from '../utils/utils';


const Aside = () => {
  const [user, setUser] = useState();
  const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate()

    const logout = useLogout();

    const signOut = async () => {
         await logout();
        navigate('/login');
    }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    /* let toolTip = document.getElementById("tooltip");

    window.addEventListener("mousemove", toolTipXY);

    function toolTipXY(e) {
        let x = e.clientX,
            y = e.clientY;
        toolTip.style.top = y + 20 + "px";
        toolTip.style.left = x + 20 + "px";
    } */

    const getUsers = async () => {
        try {
            const response = await axiosPrivate.get(endpoints.getProfil);

            console.log(response.data);
            isMounted && setUser(response.data);
        } catch (err) {
            console.error(err);
           navigate('/login', { state: { from: location }, replace: true });
        }
    }

    getUsers();


    return () => {
        isMounted = false;
        controller.abort();
    }
},[] )
  return (
    <div className="main-aside">
      <div className="profil">
        <div className="icon-profil">
          <img src={user?.photo} className="logo" alt="Mahrez" />
        </div>
        <div className="text-profil">
          <span>Welcome</span>
          <p>{user?.nom} {user?.prenom}</p>
        </div>
      </div>

      <div className="menu-aside">
        <ul>
          <li><Link className="home" to="/">Home</Link></li>
          <li><Link className="fans" to="/fan">Gestion Fans</Link></li>
          <li><Link className="admin" to="/users">Gestion Admin</Link></li>
          <li><Link className="contenus" to="/contenu">Création de contenus</Link></li>
          <li><Link className="services" to="/service">Gestion des services</Link></li>
          <li><Link className="commandes" to="/commande">Gestions des commandes</Link></li>
          <li><Link className="commandes" to="/abonnement">Gestions des abonnements</Link></li>
          <li><Link className="statistics" to="#">Statistics</Link></li>
          <li><Link className="logout" onClick={signOut} to="#">Logout</Link></li>
        </ul>
      </div>

    </div>
  );
}

export default Aside;
