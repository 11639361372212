import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import cloche from "../../assets/images/cloche.svg";
import croix from "../../assets/images/btn-fermer.svg";
import profil from "../../assets/images/profil.png";
import { WithContext as ReactTags } from "react-tag-input";
import { LineWave } from "react-loader-spinner";
import moment from "moment";
import ReactPaginate from "react-paginate";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ImagesGallery from "./ImagesGallery";
import Swal from "sweetalert2";
import Comments from "./Comments";

import Categories from "./Categories";
import { endpoints } from "../../utils/utils";

const customStyles = {
  content: {
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const baseUrl = process.env.REACT_APP_BACKEND_URL;
const delimiters = [KeyCodes.comma, KeyCodes.enter];

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const ListesContenu = ({ posts, update, postsEp }) => {
  const [tags, setTags] = React.useState();
  const axiosPrivate = useAxiosPrivate();
  const [totalPages, setTotalPages] = useState(0);
  const [suggestions, setSuggestions] = useState();
  const [suggestion, setSuggestion] = useState();
  const [categories, setCategories] = useState();
  const [loading, setloading] = useState(false);
  const [titre, setTitre] = useState();
  const [type, setType] = useState();
  const [categorie, setCategorie] = useState();
  const [description, setDescription] = useState();
  const [media, setMedia] = useState();
  const [images, setImages] = useState();
  const [removedImages, setRemovedImages] = useState([]);
  const [url, setUrl] = useState();
  const [contenu, setContenu] = useState(0);
  const [top, setTop] = useState(0);
  const [visible, setVisible] = useState();
  const [time, setTime] = useState();
  const [id, setId] = useState(0);
  const [commentsPostId, setCommentsPostId] = useState(0);
  const [comments, setComments] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [successMsg, setSuccessMsg] = useState("");
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDeleteImage = (index, url) => {
    // Créez une copie de la liste d'images en supprimant l'image à l'index spécifié.
    const updatedImages = [...images];
    const updatedMedia = [...media];
    updatedImages.splice(index, 1);
    updatedMedia.splice(index, 1);

    setMedia(updatedMedia);
    setRemovedImages(url.replace(baseUrl, ""));
    console.log(url.replace(baseUrl, ""));
    // Mettez à jour la liste d'images.
    setImages(updatedImages);
  };



  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const [startDate, setStartDate] = useState(new Date());


  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const addComment = (item) => {
    Swal.fire({
      title: "Ajouter un commentaire",
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Envoyer",
      cancelButtonText: "Annuler !",
      showLoaderOnConfirm: true,
      preConfirm: async (comment) => {
        try {
          const response = await axiosPrivate.post(
            endpoints.newComment,
            JSON.stringify({ content: comment, postId: item?.id })
          );
          console.log(response.data);
          /* setCurrentUser(response.data) */
          update();
        } catch (err) {
          console.error(err);
          Swal.showValidationMessage(`Request failed: ${err}`);
          //navigate('/login', { state: { from: location }, replace: true });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `commentaire ajouté`,
          imageUrl: result.value.avatar_url,
        });
      }
    });
  };

  const replay = (item, nom) => {
    Swal.fire({
      title: "Ajouter un commentaire",
      input: "textarea",
      inputValue: `@${nom}`,
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Envoyer",
      cancelButtonText: "Annuler !",
      showLoaderOnConfirm: true,
      preConfirm: async (comment) => {
        try {
          const response = await axiosPrivate.post(
            endpoints.commentComment,
            JSON.stringify({ content: comment, commentId: item?.id })
          );
          console.log("commentaire", response.data.title);
          setComments(response?.data?.comment);
          /* setCurrentUser(response.data) */
          update();
        } catch (err) {
          console.error(err);
          Swal.showValidationMessage(`Request failed: ${err}`);
          //navigate('/login', { state: { from: location }, replace: true });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("result", result);
        Swal.fire({
          title: `commentaire ajouté`,
          imageUrl: result.value.avatar_url,
        });
      }
    });
  };

  const block = (item, nom) => {
    Swal.fire({
      title: "Bloquer un commentaire",
      text: `Voulez-vous ${item.isActive ? "désactiver" : "activer"} le commentaire de @${nom}`,
      showCancelButton: true,
      confirmButtonText: "Valider",
      cancelButtonText: "Annuler !",
      showLoaderOnConfirm: true,
      preConfirm: async (comment) => {
        try {
          const response = await axiosPrivate.post(
            endpoints.blockComment,
            JSON.stringify({ id: item?.id, postId: id })
          );
          console.log("commentaire", response.data.title);
          setComments(response?.data?.comment);
          /* setCurrentUser(response.data) */
          update();
        } catch (err) {
          console.error(err);
          Swal.showValidationMessage(`Request failed: ${err}`);
          //navigate('/login', { state: { from: location }, replace: true });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("result", result);
        Swal.fire({
          title: `commentaire bloqué`,
          imageUrl: result.value.avatar_url,
        });
      }
    });
  };

  const openModal = (item) => {

    console.log(item);
    setloading(false);

    if (item != null) {
      console.log(item?.isPremium);
      setTitre(item?.title);
      setId(item?.id);
      setCategorie(item?.category?.libelle);
      setType(item?.media);
      setStartDate(new Date(item?.convertPubDate));
      setTime(item?.convertPubTime);
      setDescription(item?.description);
      setVisible(item?.is_visible);
      setMedia(item?.media_file);
      setImages(
        item?.media_file?.map((file) => {
          if (file?.filePath.startsWith(baseUrl) || file?.filePath.startsWith("https://lh3.googleusercontent.com/")) {
            return file?.filePath;
          }
          else {
            return `${baseUrl}${file?.filePath.substring(file?.filePath.indexOf("/images/"))}`;
          }

        })
      );


      setTop(item?.is_top);
      setContenu(!item?.isPremium);
      setTags(
        item?.tags?.map((country) => {
          return {
            id: country?.libelle,
            text: country?.libelle,
          };
        })
      );
    } else {
      setTitre("");
      setId(0);
      setCategorie();
      setType("photos");
      setStartDate();
      setTime("00:00");
      setDescription();
      setVisible();
      setMedia();
      setImages();
      setTop(false);
      setContenu(false);
      setTags([
        {
          id: "photos",
          text: "photos",
        },
      ]);
    }

    setIsOpen(true);
  };
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  const openModal2 = async (item) => {
    const response = await axiosPrivate.get(`${endpoints.getComment}/${item.id}?page=1&limit=10`);
    const total = await axiosPrivate.get(`${endpoints.getComment}/${item.id}`);

    // Mise à jour des états
    setTotalPages(total.data.totalPages);
    setCommentsPostId(item.id);
    setComments(response.data.comments);
    setIsOpen2(true);

    // Les valeurs ne seront disponibles qu'après la mise à jour des états
    console.log("Posts Bachir", response);
    console.log("total Bachir", total.data.totalPages); // Ici, totalPages ne sera pas encore mis à jour
    console.log("Bachir Id", commentsPostId); // Ici, commentsPostId ne sera pas encore mis à jour
  };

  const openModal3 = (item) => {
    console.log(item)

    setComments(item?.comment);
    setIsOpen3(true);
  };
  function afterOpenModal2() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  function afterOpenModal3() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();
    /* console.log(type);
    return ;
    console.log(postsEp); */
    const formData = new FormData();
    formData.append("media", type);
    if (id !== 0) formData.append("id", id);
    if (type !== "press" && id === 0 && (media === undefined || media?.length === 0)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Merci de selectioner un média pour cette publication",
      });
      setloading(false);
      return;
    }
    formData.append("url", url);
    formData.append("title", titre);
    formData.append("removed", removedImages);
    formData.append("description", description);
    formData.append("is_free", contenu);
    formData.append("is_visible", visible);
    formData.append("is_top", top);
    formData.append("category", categorie);
    formData.append(
      "date_publication",
      moment(startDate).format("YYYY/MM/DD") + " " + time
    );
    console.log(moment(startDate).format("YYYY/MM/DD") + " " + time);
    formData.append(
      "tags",
      tags?.map((item, index) => {
        return (index ? ", " : "") + item.text;
      })
    );
    media?.map((item) => {
      formData.append("media_file[]", item);
    });
    if (postsEp?.length > 0 && top === 1) {
      Swal.fire({
        title: "Voulez-vous effectuer cette action?",
        text: "Vous avez déjà épingler un post",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, je veux épingler ce post!",
        cancelButtonText: "Non !",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(id);
            const response = await axiosPrivate.post(
              id === 0 || id === undefined
                ? endpoints.newPost
                : endpoints.updatePost,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            /* console.log(JSON.stringify(response?.data)); */
            if (response?.data?.status === false) {
              setloading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response?.data?.response,
              });
            }
            if (response?.data?.status === false) {
              Swal.fire("Confirmer!", "Article ajouté.", "success");
              setloading(false);
              closeModal("");
              console.log(response?.data);
              update();
            }
          } catch (err) {
            setloading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Une erreur est survenu au niveau du serveur",
            });
          }
          Swal.fire("Confirmer!", "Votre post a été épingler.", "success");
        }
        setloading(false);
      });
    } else
      try {
        console.log("data" + formData);
        const response = await axiosPrivate.post(
          id === 0 || id === undefined ? endpoints.newPost : endpoints.updatePost,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        /* console.log(JSON.stringify(response?.data)); */
        if (response?.data?.status === false) {
          setloading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response?.data?.response,
          });
        }
        if (response?.data) {
          /*  setSuccessMsg("Article ajouté"); */
          closeModal("");
          setloading(false);
          console.log(response?.data);
          update();
        }
      } catch (err) {
        /* setShowBtn(false)
        setActiveBtn("")
        if (!err?.response) {
            setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Erreur de chargement');
        }
        setShowBtn(false)
        setActiveBtn("") */
        setloading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Une erreur est survenu au niveau du serveur",
        });
      }
    /*  console.log(doc) */
  };

  function closeModal() {
    setIsOpen(false);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  function closeModal3() {
    setIsOpen3(false);
  }

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const currentPageData = posts
    ?.slice(offset, offset + PER_PAGE)
    ?.map((item) => (

      <tr key={item?.id}>
        <td className="break">{item?.title}</td>
        <td>{moment(item?.convertDate).format("DD/MM/YYYY")}</td>
        <td>
          <div className={`statusUser ${item?.isPremium && "premium"}`}>{`${item?.isPremium ? "Premium" : "Free"
            }`}</div>
        </td>
        <td>{item?.category?.libelle}</td>
        <td>
          <div className="photo">
            <img
              src={item?.user?.photo ?
                item?.user?.photo.startsWith(baseUrl) ?
                  item?.user?.photo :
                  `${baseUrl}${item?.user?.photo.substring(item?.user?.photo.indexOf("/images/"))}`
                : profil}
              alt="Profil"

            />
          </div>
          {item?.user?.nom + " " + item?.user?.prenom}
        </td>
        <td>{moment(item?.convertPubDate).format("DD/MM/YYYY")} </td>
        <td className="edit-content">
          <div className="action">
            <span>Edit</span>
            <div className="">
              <a className="edit" href="#0" onClick={() => openModal(item)}>
                Modifier
              </a>
              <a
                className="comment"
                href="#0"
                onClick={() => addComment(item)}
              >
                Commenter
              </a>
              <a
                className="reply-comment"
                href="#0"
                onClick={() => openModal2(item)}
              >
                Répondre à un commentaire
              </a>
              <a
                className="publish"
                href="#0"
                onClick={() => addComment(item)}
              >
                Publier maintenant
              </a>
            </div>
          </div>
        </td>
      </tr>

    ));
  const pageCount = Math.ceil(posts?.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  let isMounted = true;
  const controller = new AbortController();
  useEffect(() => {


    handlePageClick({ selected: 0 });
    setCurrentPage(0);

    if (suggestions) {
      setSuggestion(
        suggestions?.map((country) => {
          return {
            id: country?.libelle,
            text: country?.libelle,
          };
        })
      );
    }

    getTags();
    getCat();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [posts]);

  const getTags = async () => {
    try {
      const response = await axiosPrivate.get(endpoints.getTags);
      console.log(response.data);
      isMounted && setSuggestions(response.data);
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
  };
  const getCat = async () => {
    try {
      const response = await axiosPrivate.get(endpoints.getCat);
      console.log("cat", response.data);
      isMounted && setCategories(response.data);
    } catch (err) {
      console.error(err);
      //navigate('/login', { state: { from: location }, replace: true });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
  };

  const handleMultipleImages = (evnt) => {
    const selectedFIles = [];
    const targetFiles = evnt.target.files;
    const targetFilesObject = [...targetFiles];
    targetFilesObject?.map((file) => {
      return selectedFIles.push(URL.createObjectURL(file));
    });
    setImages(selectedFIles);
    setMedia(targetFilesObject);
  };


  return (
    <>
      <div className="content-page">
        <div className="header">
          <h1>Création de contenus</h1>
          <div className="notifications">
            <div className="icons">
              <img src={cloche} alt="Notifications" />
            </div>
          </div>
        </div>
        <div className="btn-add-form bloc-header-user">
          <label>Contenus</label>
          <select>
            <option value="">Filtre by</option>
            <option value="Les 5 dernières">Les 5 dernières</option>
          </select>
          <input type="text" name="" id="" placeholder="Search" />
          {successMsg}
        </div>

        <div className="content-listes">
          <table className="tableau-list">
            <thead>
              <tr>
                <th>Titres</th>
                <th>Date</th>
                <th>Free/Premium</th>
                <th>Catégorie</th>
                <th>Créer par</th>
                <th>Publication prévue le</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{currentPageData}</tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
        <div className="btn-group">
          <a href="#0" className="add-user" onClick={() => openModal(null)}>
            Ajouter une publication
          </a>
          <a href="#0" className="add-user" onClick={() => openModal3(null)}>
            Catégories
          </a>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal contentContenu">
          <div className="headerModal">
            <h2>Ajouter une publication</h2>
            <a href='#0' onClick={closeModal}>
              <img src={croix} alt="Fermer" />
            </a>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="col-md-6">
              <div className="form-text">
                <label>Titre</label>
                <input
                  required
                  type="text"
                  placeholder="Dorem ipsum dolorI "
                  onChange={(e) => setTitre(e.target.value)}
                  value={titre}
                />
              </div>
              <div className="form-select">
                <label>Type</label>
                <select
                  value={type}
                  required
                  onChange={(e) => {
                    setType(e.target.value);
                    setTags([
                      {
                        id: e.target.value,
                        text: e.target.value,
                      },
                    ]);
                  }}
                >
                  <option value="photos">Photos</option>
                  <option value="videos">Videos</option>
                  <option value="press">Press</option>
                </select>
              </div>
              <div className="form-select">
                <label>Catégorie</label>
                <select
                  value={categorie}
                  required
                  onChange={(e) => setCategorie(e.target.value)}
                >
                  <option value="">Choisir la catégorie</option>
                  {categories?.map((item) => (

                    <option value={item?.libelle}>{item?.libelle}</option>

                  ))}
                </select>
              </div>
              <div className="form-text">
                <label>Description</label>
                <textarea
                  required
                  onChange={(e) => setDescription(e.target.value)}
                  name=""
                  id=""
                  value={description}
                ></textarea>
              </div>
              <div className="form-text">
                <label>Tags</label>
                {/* <textarea name="" id=""></textarea> */}
                <ReactTags
                  tags={tags}
                  readOnly
                  suggestions={suggestion}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="bottom"
                  autocomplete
                />
              </div>
              {type !== "press" && (
                <div className="form-file">
                  <label>Ajouter un media</label>
                  <div className="file">
                    <input
                      multiple={type === "photos" ? true : false}
                      onChange={handleMultipleImages}
                      type="file"
                      name="file"
                      id="file"
                      className="inputfile"
                    />
                    <label for="file">
                      Choisissez un fichier{" "}
                      <span>ou faites-le glisser ici</span>
                    </label>
                  </div>
                </div>
              )}
              {type === "press" && (
                <div className="form-text url">
                  <label>URL</label>
                  <input
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    type="text"
                    placeholder="Dorem ipsum dolorI "
                  />
                </div>
              )}

              <div className="form-text">
                <label>Contenu</label>
              </div>
              <div className="form-choice">
                <div className="form-check">
                  <input
                    checked={contenu == true ? true : false}
                    onChange={(e) => setContenu(e.target.value)}
                    type="radio"
                    name="contenu"
                    id="free"
                    value="1"
                  />
                  <label for="free">Free</label>
                </div>
                <div className="form-check">
                  <input
                    checked={contenu == false ? true : false}
                    onChange={(e) => setContenu(e.target.value)}
                    type="radio"
                    name="contenu"
                    id="premium"
                    value="0"
                  />
                  <label for="premium">Premium</label>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              {type !== "press" && (
                <ImagesGallery
                  images={images}
                  type={type}
                  handleDeleteImage={handleDeleteImage}
                  titre={"média"}
                />
              )}

              <div className="form-text form-date">
                <label>Date de publication</label>
                <input
                  type="text"
                  readonly
                  value={moment(startDate).format("YYYY/MM/DD")}
                  placeholder="28/28/2023"
                />
                <DatePicker
                  yearDropdownItemNumber={30}
                  showYearDropdown
                  scrollableYearDropdown
                  inline
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <div className="form-text">
                <input
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  type="time"
                />
              </div>

              <div className="form-text">
                <label>Epinglé</label>
              </div>
              <div className="form-choice">
                <div className="form-check">
                  <input
                    checked={top == true ? true : false}
                    onChange={(e) => setTop(e.target.value)}
                    type="radio"
                    name="epingler"
                    id="oui"
                    value="1"
                  />
                  <label for="oui">Oui</label>
                </div>
                <div className="form-check">
                  <input
                    checked={top == false ? true : false}
                    onChange={(e) => setTop(e.target.value)}
                    type="radio"
                    name="epingler"
                    id="non"
                    value="0"
                  />
                  <label for="non">Non</label>
                </div>
              </div>
            </div>
            <div className="form-button">
              {loading ? (
                <LineWave color={"#034B27"} loading={loading} size={50} />
              ) : (
                <button name="">Sauvegarde</button>
              )}
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen2}
        onAfterOpen={afterOpenModal2}
        onRequestClose={closeModal2}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal contentContenu">
          <div className="headerModal">
            <h2>Répondre à un commentaire</h2>
            <a href='#0' onClick={closeModal2}>
              <img src={croix} alt="Fermer" />
            </a>
          </div>
          <Comments comment={comments} addComment={replay} id={commentsPostId} totalPages={totalPages} blockComment={block} />
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen3}
        onAfterOpen={afterOpenModal3}
        onRequestClose={closeModal3}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="contentModal contentContenu">
          <div className="headerModal">
            <h2>Catégories</h2>
            <a href='#0' onClick={closeModal3}>
              <img src={croix} alt="Fermer" />
            </a>
          </div>
          <Categories categories={categories} update={getCat} />
        </div>
      </Modal>
    </>
  );
};

export default ListesContenu;
