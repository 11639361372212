import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import croix from '../../assets/images/btn-fermer.svg';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { LineWave } from "react-loader-spinner";
import { endpoints } from "../../utils/utils";

Modal.setAppElement('#root');
const customStyles = {
    content: {
        width: '50%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const Categories = ({ categories, update }) => {
    const axiosPrivate = useAxiosPrivate();
    const [loading, setloading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [successMsg, setSuccessMsg] = useState('');
    const [currentPage, setCurrentPage] = useState(0)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [titre, setTitre] = useState();
    const [cover, setCover] = useState();
    const [id, setId] = useState(0);
    const baseUrl = process.env.REACT_APP_BACKEND_URL;

    const handleSubmit = async (e) => {
        setloading(true)
        e.preventDefault();

        const formData = new FormData();
        if (id !== 0)
            formData.append('id', id)
        if (id !== 0 && cover === undefined) {
            setloading(false)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Merci de selectioner une image pour cette catégorie'
            })
            return;
        }

        formData.append('libelle', titre);
        formData.append('media_file', cover);


        try {
            console.log(id)
            const response = await axiosPrivate.post(id === 0 || id === undefined ? endpoints.newCat : endpoints.updateCat,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },

                }
            );
            /* console.log(JSON.stringify(response?.data)); */
            if (response?.data) {
                setSuccessMsg("Catégotie ajouté");
                closeModal("")
                console.log(response?.data)
                update()
                setloading(false)
            }

        } catch (err) {
            setloading(false)
            /* setShowBtn(false)
            setActiveBtn("")
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Erreur de chargement');
            }
            setShowBtn(false)
            setActiveBtn("") */
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Une erreur est survenu au niveau du serveur'
            })
        }
        /*  console.log(doc) */

    }


    const openModal = (item) => {
        console.log(item)
        setloading(false)
        if (item != null) {
            setId(item?.id);
            setTitre(item?.libelle);
            setCover(item?.media?.filePath);
        }
        else {
            setTitre("");
            setCover("");
        }

        setIsOpen(true);
    }
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    }
    function closeModal() {
        setIsOpen(false);
        setloading(false)
    }

    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const currentPageData = categories
        ?.slice(offset, offset + PER_PAGE)
        ?.map((item) => (
            <>

                <tr key={item?.id}>
                    <td>{item?.libelle}</td>
                    <td className="image-categorie">
                        <img alt='' className="image-categorie"
                            src={item?.media?.filePath.startsWith(baseUrl) || item?.media?.filePath.startsWith("https://lh3.googleusercontent.com/") ?
                                item?.media?.filePath :
                                `${baseUrl}${item?.media?.filePath.substring(item?.media?.filePath.indexOf("/images/"))}`}
                        />
                    </td>
                    <td className='edit-content'>
                        <div className="action">
                            <span>Edit</span>
                            <div className="">
                                <a className='edit' href="#0" onClick={() => openModal(item)}>Modifier</a>
                            </div>
                        </div>
                    </td>
                </tr>
            </>
        ));
    const pageCount = Math.ceil(categories?.length / PER_PAGE);
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);

    }
    useEffect(() => {

    }, [categories])

    return (
        <>


            <div className="btn-add-form bloc-header-user">
                <label>Catégories</label>
            </div>

            <div className="content-listes">
                <table className="tableau-list">
                    <thead>
                        <tr>
                            <th>Titre</th>
                            <th>Image</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData}
                    </tbody>
                </table>
            </div>
            <ReactPaginate
                previousLabel={"←"}
                nextLabel={"→"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            />
            <a href="#0" className="add-user" onClick={() => openModal(null)}>Ajouter une catégorie</a>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="contentModal contentContenu">
                    <div className="headerModal">
                        <h2>Ajouter une publication</h2>
                        <a href='#0' onClick={closeModal}><img src={croix} alt="Fermer" /></a>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="col-md-6">
                            <div className="form-text">
                                <label>Titre</label>
                                <input required type="text" placeholder="Dorem ipsum dolorI " onChange={(e) => setTitre(e.target.value)} value={titre} />
                            </div>



                        </div>

                        <div className="col-md-6">
                            <div className="form-file">
                                <label>Image</label>
                                <div className="file">
                                    <input onChange={(e) => setCover(e.target.files[0])} type="file" name="cover" id="cover" className="inputfile" />
                                    <label for="cover">Choisissez un fichier <span>ou faites-le glisser ici</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-button">
                            {
                                loading ?

                                    <LineWave color={'#034B27'} loading={loading} size={50} />
                                    :

                                    <button name="">Sauvegarde</button>
                            }

                        </div>
                    </form>

                </div>
            </Modal>

        </>

    )
}
export default Categories;