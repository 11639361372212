export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL + '/api';

export const endpoints = {
  loginUrl: '/login_check',
  getAbonnement: '/admin/service/souscription',
  refreshToken: '/token/refresh',
  getProfil: '/admin/profil',
  getCommande: '/admin/service/commandes',
  getCommandeById: '/admin/service/commande',
  changeStatus: '/admin/service/commande/change/etat',
  newSouscription: '/admin/service/souscription/add',
  getUser: 'admin/users?page=1&limit=10',
  getAdminUser: '/admin/users/admin',
  disableSubscription: '/admin/service/souscription/desactive',
  stripeVerify: '/admin/verify-payment',
  getService: '/admin/service',
  newUser: '/admin/new/user',
  bloqueUser: '/admin/user/disable',
  updateUser: '/admin/edit/user',
  activeUser: '/admin/user/enable',
  getDevise: '/admin/service/devise',
  disableService: '/admin/service/disable',
  newService: '/admin/service/new',
  updateService: '/admin/service/edit',
  newCat: '/admin/post/new/categorie',
  updateCat: '/admin/post/edit/categorie',
  getPost: '/admin/post',
  getPinnedPost: '/admin/post/epingler',
  getTags: '/admin/post/tags',
  getCat: '/admin/post/categories',
  getComment: 'admin/post/comment',
  newComment: '/admin/post/comment',
  commentComment: '/admin/post/comment/comment',
  newPost: '/admin/post/new',
  updatePost: '/admin/post/edit',
  getStats: '/admin/stats',
  newTag: '/tags',
  blockComment: '/admin/comment/disable'
};